<template>
  <b-modal
    visible
    scrollable
    centered
    size="s"
    :title="title"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <div v-if="files.length">
      <div class="d-flex justify-content-between">
        <p class="font-weight-bold">
          Название
        </p>

        <p class="font-weight-bold">
          Статус
        </p>
      </div>

      <div class="mt-2">
        <div
          v-for="file in files"
          :key="file.id"
          class="d-flex justify-content-between my-2 align-items-start modal__file-item"
        >
          <div class="overflow-hidden">
            {{ file.name }}
          </div>

          <div class="d-flex align-items-center ml-2">
            <div
              style="width: 8px; height: 8px; min-width: 8px;"
              :style="{'background-color': statuses[file.status].color}"
              class="rounded-circle w-3 h-3 mr-2"
            />

            <span class="modal__status-text">
              {{ statuses[file.status].title }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-center"
      :class="{'mt-4': files.length}"
    >
      <!--      <input-->
      <!--        ref="file-input"-->
      <!--        type="file"-->
      <!--        :multiple="multiple"-->
      <!--        :accept="accept"-->
      <!--        class="sr-only"-->
      <!--        @change="changeInputFiles"-->
      <!--      >-->
      <!--      <b-button-->
      <!--        pill-->
      <!--        variant="primary"-->
      <!--        @click="openInput"-->
      <!--      >-->
      <!--        Добавить файлы-->
      <!--      </b-button>-->
      <b-form-file
        :accept="accept"
        plain
        :multiple="multiple"
        @change="changeInputFiles"
      />
    </div>

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onSave"
      >
        Загрузить
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { api } from '@/helpers/api';
import { getFileExtension } from '@/helpers/utils';
import { fileService } from '@/services';

export default {
  name: 'FilesUploadModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Загрузка документа',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    saveFileCallback: {
      type: Function,
      default: () => {},
    },
    cbArgs: {
      type: Array,
      default: () => ([]),
    },
    fileApi: {
      type: String,
      default: '',
    },
    registerPatientInQueue: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isOpen: true,
    isLoading: false,
    files: [],
    statuses: {
      pending: {
        title: 'загружается',
        color: 'gray',
      },
      success: {
        title: 'успешно загружено',
        color: 'green',
      },
      error: {
        title: 'произошла ошибка',
        color: 'red',
      },
    },
  }),
  computed: {
    filesPending() {
      return this.files.filter((file) => file.status === 'pending');
    },
    filesSuccess() {
      return this.files.filter((file) => file.status === 'success');
    },
    filesError() {
      return this.files.filter((file) => file.status === 'error');
    },
  },

  methods: {
    openInput() {
      if (this.isLoading) return;

      this.$refs['file-input'].click();
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    changeInputFiles(event) {
      const files = Array.from(event.target.files);
      this.files = [...this.files, ...files.map((file) => ({
        id: this.getRandomInt(1, 1000), name: file.name, status: 'pending', file,
      }))];

      this.filesPending.forEach((fileObject) => this.sendFile(fileObject));
    },
    async sendFile(fileObject) {
      if (this.registerPatientInQueue) {
        await this.sendFileToFileServer(fileObject);
      } else {
        const extension = getFileExtension(fileObject.name);
        try {
          const fd = new FormData();
          console.log('fd', fd);
          if (this.fileApi) {
            fd.append('file', fileObject.file);
          }
          const params = new URLSearchParams();
          params.append('fileName', fileObject.name);

          const { data } = await api.post(this.fileApi || `File/${extension}`, this.fileApi ? fd : fileObject.file, {
            headers: {
              'Content-Type': fileObject.file.type,
            },
            params,
          });
          const existingFileIndex = this.files.indexOf(fileObject);
          this.files.splice(existingFileIndex, 1, { ...fileObject, status: 'success', responseData: data });
        } catch (e) {
          const existingFileIndex = this.files.indexOf(fileObject);

          this.files.splice(existingFileIndex, 1, { ...fileObject, status: 'error' });
        }
      }
    },
    async sendFileToFileServer(fileObject) {
      try {
        const fileNames = await fileService.send(fileObject.file);
        const existingFileIndex = this.files.indexOf(fileObject);
        this.files.splice(existingFileIndex, 1, { ...fileObject, status: 'success', responseData: fileNames });
      } catch (err) {
        const existingFileIndex = this.files.indexOf(fileObject);
        this.files.splice(existingFileIndex, 1, { ...fileObject, status: 'error' });
      }
    },
    onSave() {
      if (this.saveFileCallback) this.saveFileCallback(this.filesSuccess, ...this.cbArgs);

      this.onClose();
    },
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__status-text {
    white-space: nowrap;
  }

  &__file-item {
    &:not(:last-child) {
      padding-bottom: 10px;
      border-bottom: 1px solid #f1f1f1;
    }
  }
}
</style>
